// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/FormError.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/FormError.tsx");
  import.meta.hot.lastModified = "1697103404010.1355";
}
// REMIX HMR END

import { Paragraph } from "./Paragraph";
import { NamedIcon } from "./NamedIcon";
import { motion } from "framer-motion";
import { cn } from "~/utils/cn";
export function FormError({
  children,
  id,
  className
}) {
  return <>
      {children && <motion.div initial={{
      opacity: 0
    }} animate={{
      opacity: 1
    }} transition={{
      duration: 0.3
    }} className={cn("flex items-start gap-0.5", className)}>
          <NamedIcon name="error" className="h-4 w-4 shrink-0 justify-start" />
          <Paragraph id={id} variant="extra-small" className="text-rose-500">
            {children}
          </Paragraph>
        </motion.div>}
    </>;
}
_c = FormError;
export function ZodFormErrors({
  errors,
  path
}) {
  if (errors.length === 0) {
    return null;
  }
  const relevantErrors = errors.filter(error => {
    return error.path.join(".") === path.join(".");
  });
  if (relevantErrors.length === 0) {
    return null;
  }
  return <div className="col-span-full mt-1 text-sm text-rose-600">
      {relevantErrors.map((error, index) => <FormError key={index}>{error.message}</FormError>)}
    </div>;
}
_c2 = ZodFormErrors;
var _c, _c2;
$RefreshReg$(_c, "FormError");
$RefreshReg$(_c2, "ZodFormErrors");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;